function ReportPage(props) {
  const { lang, label, chatCenter, toast, info } = props;

  const [loading, setLoading] = React.useState(true);
  const [itemOffset, setItemOffset] = React.useState(0);
  const [pageCount, setPageCount] = React.useState(0);
  const [allLeadCount, setAllLeadCount] = React.useState(0);
  const [allContactCount, setAllContactCount] = React.useState(0);
  const [pageData, setPageData] = React.useState([]);
  const [allChatroomCount, setAllChatroomCount] = React.useState(0);
  const [dateTime, setDateTime] = React.useState({});
  const [csvData, setCsvData] = React.useState([]);
  const [currentItems, setCurrentItems] = React.useState([]);
  const [reportData, setReportData] = React.useState([]);
  const [contactData, setContactData] = React.useState([]);
  const [labelData, setLabelData] = React.useState([]);
  const [facebookName, setFacebookName] = React.useState([]);
  const [lineName, setLineName] = React.useState([]);
  const [instagramName, setInstagramName] = React.useState([]);
  const [offsetServer, setOffsetServer] = React.useState(0);
  const [visibilityState, setVisibilityState] = React.useState({});
  const [reportDetail, setReportDetail] = React.useState([]);

  let isMobile = window.innerWidth < 768;

  const toggleDetails = (day, source, visibilityState, setVisibilityState) => {
    if (!source || !day) {
        throw new Error('source or day is undefined');
    }

    const dayVisibility = visibilityState[day] || {};
    const isCurrentlyVisible = dayVisibility[source] || false;

    setVisibilityState({
        ...visibilityState,
        [day]: {
            ...dayVisibility,
            [source]: !isCurrentlyVisible,
        },
    });
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 20) % reportData.length;
    setItemOffset(newOffset);
    setCurrentItems(reportData.slice(newOffset, newOffset + 20));

    if (isMobile) {
      const TableRect = document.getElementById('table-div');
      TableRect.scrollTop = 0;
      TableRect.scrollLeft = 0;
    } else {
      const TableRect = document.getElementById("report-page-component");
      TableRect.scrollTop = 630;
    }
  };

  function formatDateCustom(date, format = "DD-MM-YYYY HH:mm") {
    let m = moment(date);
    let formattedDate = m.format(format);

    if (props.lang.userLang === "th") {
      const gregorianYear = m.year();
      const buddhistYear = gregorianYear + 543;
      formattedDate = formattedDate.replace(String(gregorianYear), String(buddhistYear));
    }

    return formattedDate;
  }

  React.useEffect(() => {
    const exportData = [
      [
        lang.lang.__date,
        lang.lang.__contact,
        lang.lang.__contact_name,
        lang.lang.__total_chats,
        lang.lang.__Existing_chats,
        lang.lang.__New_chats,
        lang.lang.__contacts,
        lang.lang.__leads,
      ],
    ];

    if (Array.isArray(reportDetail) && reportDetail.length > 0) {
      reportDetail.forEach((val) => {
        const formatDate = formatDateCustom(val.day, "DD MMM YY");

        exportData.push([ 
          formatDate,
          val.type.charAt(0).toUpperCase() + val.type.slice(1),
          val.Name,
          val.chatCount,
          val.returnCount,
          val.createCount,
          val.contactCount,
          val.leadCount
        ]);
      
      });

      setCsvData(exportData);
    }
  }, [currentItems]);

  function CSVDownloader() {
    const downloadCSV = () => {
      let dataArray = [...csvData];
      dataArray.unshift(
        ["R-Chat Report"],
        [
          `${formatDateCustom(
            dateTime.start_date,
            "DD MMM YY"
          )} - ${formatDateCustom(dateTime.end_date, "DD MMM YY")}`,
        ],
        [
          `${contactData.length} ${lang.lang.__contact}, ${labelData.length} ${lang.lang.__label}`,
        ]
      );
    
      const wb = XLSX.utils.book_new();
      
      const ws = XLSX.utils.aoa_to_sheet(dataArray);

      ws['!merges'] = [
        { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
        { s: { r: 1, c: 0 }, e: { r: 1, c: 7 } },
        { s: { r: 2, c: 0 }, e: { r: 2, c: 7 } },
      ];

      ws['A1'].s = ws['A2'].s = ws['A3'].s = {
        alignment: {
          horizontal: 'center',
          vertical: 'center',
        },
      };

      XLSX.utils.book_append_sheet(wb, ws, 'Report');
    
      const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      saveAs(new Blob([wbout], { type: 'application/octet-stream' }), `Report-${formatDateCustom(new Date().toUTCString(), 'DD-MMM-YYYY HH:mm:ss')}.xlsx`);
    };

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          style={{
            minWidth: 100,
          }}
          onClick={() => downloadCSV()}
        >
          {props.lang.lang.__export_data}
        </Button>
      </div>
    );
  }

  function AllCountBox({ count, text }) {
    return (
      <div className="sp-panel -h50" style={{ minHeight: 100 }}>
        <div className="body -align-center -align-middle">
          <div className="number">{count.toLocaleString()}</div>
          <div className="text">{text}</div>
        </div>
      </div>
    );
  }

  const getReport = (updateDate, source, label, limit) => {
    let startDate = moment(updateDate.start_date).format("YYYY-MM-DD");
    let endDate = moment(updateDate.end_date).format("YYYY-MM-DD");
    const offsetWeb = new Date().getTimezoneOffset() * -1;
    setLoading(true);
    let labelId =
      Array.isArray(label) &&
      label.map((val) => {
        return val.label_id;
      });

    axios({
      method: "POST",
      url:
        backend +
        "report/" +
        props.businessData.uid +
        "/" +
        props.businessData.appId,
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "X-VC-Access-Token": props.businessData.access,
      },
      data: JSON.stringify({
        offSetTime: offsetWeb,
        start: startDate,
        end: endDate,
        source: source,
        label: labelId,
        offset: itemOffset,
        limit: limit,
      }),
    }).then((res) => {
      if (res && res.data && res.data.result === 0) {
        setReportData(res.data.data);
        setAllLeadCount(res.data.allLeadCount);
        setAllContactCount(res.data.allContactCount);
        setAllChatroomCount(res.data.allChatroomCount);
        setReportDetail(res.data.sortedPageData);
        setCurrentItems(res.data.data.slice(0, 20));
        setPageCount(Math.ceil(res.data.data.length / 20));
        setDateTime({ start_date: startDate, end_date: endDate });
        setPageData(res.data.dataPage);
        setFacebookName(res.data.facebookName);
        setLineName(res.data.lineName);
        setInstagramName(res.data.instagramName);
        setContactData(source);
        setLabelData(label);
        setLoading(false);
        setOffsetServer(offsetWeb - res.data.offSetTime);
        shappyMain.log('Report data: ', res.data);
      } else {
        setLoading(false);
        toast.error(lang.lang.__report_get_report_fail);
        shappyMain.log('ERROR: ', res.data.message);
      }
    }).catch((err) => {
      setLoading(false);
      toast.error(lang.lang.__report_get_report_fail);
    });
  };

  const renderRow = (label, count, returnCount, createCount, contact, lead) => {
    return (
      <tr>
        <DataRow className="stickyColumn"
          style={{
            textAlign: 'left',
          }}
        >
          <CellData style={{marginLeft: '32px'}}>
            {label.charAt(0).toUpperCase() + label.slice(1).toLowerCase()}
          </CellData>
        </DataRow>
        <DataRow>
          <CellData style={{ fontSize: '16px' }}>
            {count.toLocaleString()}
          </CellData>
        </DataRow>
        <DataRow>
          <CellData style={{ fontSize: '16px' }}>
            {returnCount.toLocaleString()}
          </CellData>
        </DataRow>
        <DataRow>
          <CellData style={{ fontSize: '16px' }}>
            {createCount.toLocaleString()}
          </CellData>
        </DataRow>
        <DataRow>
          <CellData style={{ fontSize: '16px' }}>
            {contact.toLocaleString()}
          </CellData>
        </DataRow>
        <DataRow>
          <CellData style={{ fontSize: '16px' }}>
            {lead.toLocaleString()}
          </CellData>
        </DataRow>
      </tr>
    )
  }

  const renderRowClick = (day, label, count, returnCount, createCount, contact, lead) => {
    return (
      <tr>
        <DataRow className="stickyColumn"
          style={{
            textAlign: 'left',
          }}
        >
          <CellData> 
            <button onClick={() => toggleDetails(day, label, visibilityState, setVisibilityState)} 
              style={{
                border: 'none', 
                padding: '0',
              }}>
              <i className={`icon ${visibilityState[day] && visibilityState[day][label] ? 'icon-up-dir' : 'icon-down-dir'}`}></i> 
              &nbsp;&nbsp;&nbsp;&nbsp;
              {label == 'line' ? 'LINE OA' : label.charAt(0).toUpperCase() + label.slice(1).toLowerCase()}
            </button>
          </CellData>
        </DataRow>
        <DataRow>
          <CellData style={{ fontSize: '16px' }}>
            {count.toLocaleString()}
          </CellData>
        </DataRow>
        <DataRow>
          <CellData style={{ fontSize: '16px' }}>
            {returnCount.toLocaleString()}
          </CellData>
        </DataRow>
        <DataRow>
          <CellData style={{ fontSize: '16px' }}>
            {createCount.toLocaleString()}
          </CellData>
        </DataRow>
        <DataRow>
          <CellData style={{ fontSize: '16px' }}>
            {contact.toLocaleString()}
          </CellData>
        </DataRow>
        <DataRow>
          <CellData style={{ fontSize: '16px' }}>
            {lead.toLocaleString()}
          </CellData>
        </DataRow>
      </tr>
    )
  }

  const renderCell = (pageItem, label) => {
    const Values = { 
      return: pageItem.returnCount, 
      create: pageItem.createCount, 
      contact: pageItem.contactCount, 
      lead: pageItem.leadCount, 
      chat: pageItem.chatCount };
    return (
      <CellNumber>
        {Values[label] ? Values[label].toLocaleString() : '0'}
      </CellNumber>
    );
  };

  
  const renderRowDetail = (day, source, name) => {
    let elementRow = [];
    name.forEach((title, index) => {
      const pageItem = source.find(val => val.day === day && val.id === title.id);
      if (pageItem) {
        elementRow.push(
          <tr key={index}>
            <DataRowName className="stickyColumn" title={title.title}> 
              <CellDetail style={{
                'display': '-webkit-box',
                '-webkit-line-clamp': '2',
                '-webkit-box-orient': 'vertical',
                'overflow': 'hidden',
                'text-align': 'left',
                'text-wrap': 'wrap',
                'min-height': '20px',
            }}>  
                {title.title}
              </CellDetail>
            </DataRowName>
            <DataRow>
              {renderCell(pageItem, 'chat')}
            </DataRow>
            <DataRow>
              {renderCell(pageItem, 'return')}
            </DataRow>
            <DataRow>
              {renderCell(pageItem, 'create')}
            </DataRow>
            <DataRow>
              {renderCell(pageItem, 'contact')}
            </DataRow>
            <DataRow>
              {renderCell(pageItem, 'lead')}
            </DataRow>
          </tr>
        )
      }
    });
    return elementRow;
  }
      
  const isUndefinedCondition = (data) => {
    return (
      (!_.isUndefined(data[0]) && data[0] !== 0) ||
      (!_.isUndefined(data[1]) && data[1] !== 0) ||
      (!_.isUndefined(data[2]) && data[2] !== 0)
    );
  };
  return (
    <div>
      <div>
        <HeaderFilter
          getReport={getReport}
          chatCenter={chatCenter}
          r_chat_link={info && info.is_on}
          label={label}
          lang={lang.lang}
          formatDateCustom={formatDateCustom}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Detail>
          {loading && (
            <div className="sp-placeholder">
              <div className="sp-text-help">
                <i className="icon icon-circle-notch animate-spin"></i>{" "}
                <span>{lang.lang.__loading}</span>
              </div>
            </div>
          )}
          {!loading && (
            <div
              style={{
                width: "100%",
              }}
            >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "30px",
                    padding: "0 7px",
                  }}
                >
                  <div>
                    <div>
                       <span className="sp-label" style={{ fontSize: isMobile ? "16px" : "20px" }}>
                      {props.lang.lang.__data_from}
                      &nbsp;&nbsp;
                      {formatDateCustom(
                        dateTime.start_date,
                        "DD MMM YY"
                      )} - {formatDateCustom(dateTime.end_date, "DD MMM YY")}
                    </span>
                      </div>
                    <div
            class="sp-text-help"
            style={{ fontSize: "12px", display: isMobile ? "block" : "inline" }}
          >
            {contactData.length} {lang.lang.__contact}
            &nbsp;&nbsp;
            {labelData.length > 0
              ? ` ${labelData.length} ${lang.lang.__label}`
              : ""}
          </div>
                  </div>

                  {reportData.length > 0 ? (
                    <CSVDownloader>{lang.lang.__export_data}</CSVDownloader>
                  ) : null}
                </div>
              <ReportChart
                lang={lang}
                users={reportData}
                formatDateCustom={formatDateCustom}
                dateTime={dateTime}
                offsetServer={offsetServer}
              />
              <div
                className="analytic-box"
                style={{
                  display: "grid",
                  gridTemplateColumns: isMobile ? "1fr 1fr" : "1fr 1fr 1fr",
                  gridTemplateRows: isMobile ? "1fr 1fr" : "1fr",
                  gap: 14,
                  margin: "20px 0",
                }}
              >
                <AllCountBox
                  count={allChatroomCount}
                  text={`${lang.lang.__chats}`}
                />
                <AllCountBox
                  count={allContactCount}
                  text={`${lang.lang.__contacts}`}
                />
                <AllCountBox
                  count={allLeadCount}
                  text={`${lang.lang.__leads}`}
                />
              </div>
              <TableDiv id={"table-div"}>
                <Table className="table" style={{ borderCollapse: "separate" }}>
                  <thead style={{ position: "sticky", top: "-1px", zIndex: 2 }}>
                    <tr>
                      <Header
                        className="stickyColumn"
                        style={{
                          maxWidth: "480px",
                        }}
                      >
                        &nbsp;
                      </Header>
                      <Header style={{ width: "12%" }}>
                        {lang.lang.__total_chats}
                      </Header>
                      <Header style={{ width: "12%" }}>
                        {lang.lang.__Existing_chats}
                      </Header>
                      <Header style={{ width: "12%" }}>
                        {lang.lang.__New_chats}
                      </Header>
                      <Header style={{ width: "12%" }}>
                        {lang.lang.__contacts}
                      </Header>
                      <Header style={{ width: "12%" }}>
                        {lang.lang.__leads}
                      </Header>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(currentItems) && currentItems.length ? (
                      currentItems.map((val, index) => {
                        return (
                          <React.Fragment key={val.day}>
                            <tr style={{ backgroundColor: "#EEEEEE" }}>
                              <td
                                className="stickyColumn"
                                style={{
                                  fontSize: "16px",
                                  minWidth: "160px",
                                  border: "none",
                                }}
                              >
                                {formatDateCustom(val.day, "DD MMM YY")}
                              </td>
                              <Data
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 700,
                                  border: "none",
                                }}
                              >
                                {val.facebookCount +
                                  val.lineCount +
                                  val.instagramCount +
                                  val.websiteCount || 0}
                              </Data>
                              <Data
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 700,
                                  border: "none",
                                }}
                              >
                                {val.returnFacebook +
                                  val.returnLine +
                                  val.returnInstagram +
                                  val.returnWebsite || 0}
                              </Data>
                              <Data
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 700,
                                  border: "none",
                                }}
                              >
                                {val.createFacebook +
                                  val.createLine +
                                  val.createInstagram +
                                  val.createWebsite || 0}
                              </Data>
                              <Data
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 700,
                                  border: "none",
                                }}
                              >
                                {val.contactFacebook +
                                  val.contactLine +
                                  val.contactInstagram +
                                  val.contactWebsite || 0}
                              </Data>
                              <Data
                                style={{
                                  fontSize: "16px",
                                  fontWeight: 700,
                                  border: "none",
                                }}
                              >
                                {val.leadFacebook +
                                  val.leadLine +
                                  val.leadInstagram +
                                  val.leadWebsite || 0}
                              </Data>
                            </tr>
                            <>
                              <tr style={{ height: "15px" }}>
                                <DataRow
                                  className="stickyColumn"
                                  style={{}}
                                ></DataRow>
                                <DataRow colSpan="3"></DataRow>
                              </tr>
                              {isUndefinedCondition([
                                val.facebookCount,
                                val.leadFacebook,
                                val.contactFacebook,
                              ]) &&
                                renderRowClick(
                                  val.day,
                                  "facebook",
                                  val.facebookCount,
                                  val.returnFacebook,
                                  val.createFacebook,
                                  val.contactFacebook,
                                  val.leadFacebook
                                )}
                              {isUndefinedCondition([
                                val.facebookCount,
                                val.leadFacebook,
                                val.contactFacebook,
                              ]) &&
                                visibilityState[val.day] &&
                                visibilityState[val.day]["facebook"] &&
                                renderRowDetail(
                                  val.day,
                                  pageData.facebook,
                                  facebookName
                                )}

                              {isUndefinedCondition([
                                val.lineCount,
                                val.leadLine,
                                val.contactLine,
                              ]) &&
                                renderRowClick(
                                  val.day,
                                  "line",
                                  val.lineCount,
                                  val.returnLine,
                                  val.createLine,
                                  val.contactLine,
                                  val.leadLine
                                )}
                              {isUndefinedCondition([
                                val.lineCount,
                                val.leadLine,
                                val.contactLine,
                              ]) &&
                                visibilityState[val.day] &&
                                visibilityState[val.day]["line"] &&
                                renderRowDetail(
                                  val.day,
                                  pageData.line,
                                  lineName
                                )}

                              {isUndefinedCondition([
                                val.instagramCount,
                                val.leadInstagram,
                                val.contactInstagram,
                              ]) &&
                                renderRowClick(
                                  val.day,
                                  "instagram",
                                  val.instagramCount,
                                  val.returnInstagram,
                                  val.createInstagram,
                                  val.contactInstagram,
                                  val.leadInstagram
                                )}
                              {isUndefinedCondition([
                                val.instagramCount,
                                val.leadInstagram,
                                val.contactInstagram,
                              ]) &&
                                visibilityState[val.day] &&
                                visibilityState[val.day]["instagram"] &&
                                renderRowDetail(
                                  val.day,
                                  pageData.instagram,
                                  instagramName
                                )}

                              {isUndefinedCondition([
                                val.websiteCount,
                                val.leadWebsite,
                                val.contactWebsite,
                              ]) &&
                                renderRow(
                                  "website",
                                  val.websiteCount,
                                  val.returnWebsite,
                                  val.createWebsite,
                                  val.contactWebsite,
                                  val.leadWebsite
                                )}

                              <tr style={{ height: "15px" }}>
                                <DataRow className="stickyColumn"></DataRow>
                                <DataRow colSpan="3"></DataRow>
                              </tr>
                            </>
                          </React.Fragment>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colspan="6"
                          style={{
                            fontSize: "14px",
                            fontStyle: "italic",
                            border: "none",
                            color: "#ABABAD",
                            textAlign: "center",
                          }}
                        >
                          {lang.lang.__no_data}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </TableDiv>
              { Array.isArray(reportData) && pageCount > 1 && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <ReactPaginate.default
                    breakLabel="..."
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    subContainerClassName={"pages pagination"}
                    marginPagesDisplayed={1}
                    activeClassName={"active"}
                    pageClassName={"page-items"}
                    containerClassName={
                      "pagination justify-content-center my-3"
                    }
                    nextLabel={<i className="icon-right-dir" />}
                    previousLabel={<i className="icon-left-dir" />}
                    renderOnZeroPageCount={null}
                  />
                </div>
              )}
            </div>
          )}
        </Detail>
      </div>
    </div>
  );
}

const Image = window.styled.img`
  width: 2%;
  height: 2%;
`;

const TableDiv = window.styled.div`
  width: 100%;
  overflow: auto;
@media (max-width: 767px) {
  max-height: 76vh;
}
  background-color: white;
  table, table * {
    background-color: inherit;
  }
`;

const Table = window.styled.table`
  width: 100%;
  border-bottom:0.5px solid #E1E7F2;
`;

const Header = window.styled.th`
  text-align: right;
  min-width: 115px;
`;

const Data = window.styled.td`
  vertical-align: middle !important;
  text-align: right;
`;

const DataRow = window.styled.td`
  vertical-align: middle !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  border-top: 0 !important;
  min-height: 20px !important;
  text-align: right;
`;

const DataRowName = window.styled.td`
  padding-top: 4px !important;
  padding-bottom: 2px !important;
  border-top: 0 !important;
`;

const CellData = window.styled.div`
  margin-bottom: 2px; 
  margin-top: 2px;
`;

const CellDetail = window.styled.div` 
  color: #ABABAD;
  font-size: 0.9em; 
  padding-left: 45px; 
`;

const CellNumber = window.styled.div`
  color: #ABABAD;
  font-size: 0.9em; 
`;

const Detail = window.styled.div`
   padding: 0 4%;
   width: 100%;
   max-width: 1300px;
    @media (max-width: 767px) {
      padding: 0 0;
    }
`;
